import React from 'react';
import Clipboard from 'react-clipboard.js';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './WordActions.scss';

export const WordActions = () => {
    const canShare = 'share' in navigator;

    const urlCopied = ({ action, text }) => {
        toast('Länk kopierad - använd CTRL-V för att klistra in', {
            autoClose: 3000,
            type: toast.TYPE.SUCCESS,
        });
    };

    const getLink = () => {
        let url = document.location.href;
        const canonicalElement = document.querySelector('link[rel=canonical]');
        if (canonicalElement !== null) {
            url = canonicalElement.href;
        }
        return url;
    };

    const shareLink = (event) => {
        event.preventDefault();
        let url = getLink();
        let title = document.title;
        //navigator.share({ url: url });
        if ('share' in navigator) {
            navigator
                .share({
                    url,
                    title,
                })
                .then((...args) => {
                    console.log(args);
                    console.log('Shared');
                })
                .catch((error) => {
                    console.log('No shared');
                });
        }
    };

    return (
        <div>
            <Clipboard
                component={'a'}
                className="card-link"
                button-href={'#'}
                data-clipboard-text={getLink()}
                onSuccess={urlCopied}>
                Kopiera
            </Clipboard>

            {canShare && (
                <a href="#" onClick={shareLink} className="card-link">
                    Dela
                </a>
            )}
            <ToastContainer transition={Flip} position={'top-center'} hideProgressBar={true} />
        </div>
    );
};
