import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { soundStore } from '../doon/stores/SoundStore';
import { Loader, Pause, Play, Volume2 } from 'react-feather';
import analytics from '../doon/analytics';

const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};

const Player = ({ url }) => {
    const [playing, toggle] = useAudio(url);

    return (
        <div>
            <button className={'btn btn-sm btn-outline-primary'} onClick={toggle}>
                {playing ? (
                    <>
                        <Pause size={14} /> Pausa
                    </>
                ) : (
                    <>
                        <Play size={14} /> Spela upp
                    </>
                )}
            </button>
        </div>
    );
};

export const WordPlayer = observer(({ id, word }) => {
    const [audio, setAudio] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadSound = () => {
        setLoading(true);
        soundStore.loadSound(id).then((sound) => {
            setAudio(sound);
            setLoading(false);
            analytics.trackEvent('Sound', 'Load', word);
        });
    };

    return (
        <div className={'word-player'}>
            <div className="word-player-audio">
                <a
                    className={'word-player-load-sound'}
                    href={'#load'}
                    onClick={(event) => {
                        event.preventDefault();
                        !audio && loadSound();
                    }}>
                    <Volume2 size={14} /> Hur uttalas ordet?
                </a>
                <div className={'word-player-controls'}>
                    {loading && (
                        <span>
                            <Loader size={14} />
                        </span>
                    )}
                    {audio && <Player url={audio} />}
                </div>
            </div>
        </div>
    );
});
