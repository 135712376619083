import { computed, observable } from 'mobx';
import client from '../client';
import analytics from '../analytics';
import store from 'store2';
import { querySelectorAllArray, elm } from '../utils';
const Fingerprint2 = require('fingerprintjs2');

class User {
    @observable hash: string;
    @observable likes: any[];
    @observable consent: boolean;
    @observable credits: number = 100;
    private _csrf: string;

    constructor() {
        this.hash = '';
        this._csrf = '';
        this.likes = [];
        this.consent = !!store.get('doon_gdpr');

        this.getUserId().then((userId) => {
            this.loadLikes();
            this.insertHiddenParams();
        });
    }

    setConsent(consent: boolean) {
        this.consent = true;
        store.set('doon_gdpr', consent);
    }

    getUserId() {
        return new Promise((resolve, reject) => {
            let storedClientId = store.get('doon_client_id');
            if (!storedClientId) {
                const options = {};
                Fingerprint2.get(options, (components) => {
                    const values = components.map(function (component) {
                        return component.value;
                    });
                    const murmur = Fingerprint2.x64hash128(values.join(''), 31);
                    this.hash = murmur;
                    store.set('doon_client_id', murmur);
                    resolve(murmur);
                });
            } else {
                this.hash = storedClientId;
                resolve(storedClientId);
            }
        });
    }

    loadLikes() {}

    @computed
    get domains() {
        return this.likes.filter((like) => {
            return like.type === 'domain';
        });
    }

    @computed
    get words() {
        return this.likes.filter((like) => {
            return like.type === 'word';
        });
    }

    setLike(entityId: number, entityType: string) {
        let params = {
            user: this.hash,
            domain: entityId,
            type: entityType,
        };
        client.post('/like', params).then(
            (response: any) => {
                this.loadLikes();
                analytics.trackEvent('DomainSearch', 'Like', entityType);
            },
            (error: any) => {
                analytics.trackEvent(
                    'DomainSearch',
                    'Error',
                    'Doon' + entityType + 'Like',
                    null,
                    true
                );
            }
        );
    }

    private insertHiddenParams() {
        let fields = querySelectorAllArray('.user-token');
        let tokenField = elm('input', {
            type: 'hidden',
            name: 'token',
            value: this.hash,
        });
        fields.forEach((field) => {
            field.replaceWith(tokenField);
        });
    }

    get csrf(): string {
        return this._csrf;
    }

    set csrf(value: string) {
        this._csrf = value;
    }
}

const user = new User();

export default user;
