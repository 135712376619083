import ReactDOM from 'react-dom';
import DoonWordFilter from './words/filter';
import React from 'react';
import { querySelectorAllArray } from './utils';
import SynonymForm from './synonyms';
import DoonWordSearch from './word';
import DoonWordSayings from './words/sayings';
import { WordActions } from './words/WordActions';
import { WordPlayer } from '../components/WordPlayer';

export class Word {
    constructor() {
        this.isInitialized = false;
        this.init();
    }

    async init() {
        if (this.isInitialized) {
            return;
        }
        this.initAnagrams();
        this.initSayings();
        this.initSynonyms();
        this.initSimilar();
        this.initTags();
        this.initWordFilter();
        this.initSearch();
        this.initWordActions();
        await this.initPlayer();

        this.isInitialized = true;
    }

    initSayings() {
        querySelectorAllArray('.word-sayings').forEach((wrapper) => {
            ReactDOM.render(<DoonWordSayings {...wrapper.dataset} />, wrapper);
        });
    }

    initSynonyms() {
        let synonymForms = querySelectorAllArray('.synonym-form');
        synonymForms.forEach((synonymForm) => {
            this.synonyms = <SynonymForm {...synonymForm.dataset} />;
            ReactDOM.render(this.synonyms, synonymForm);
        });
    }

    initSearch() {
        let wordSearchWrapper = document.getElementById('word-search');
        if (wordSearchWrapper) {
            this.wordSearch = <DoonWordSearch />;
            ReactDOM.render(this.wordSearch, wordSearchWrapper);
        }
    }

    initTags() {
        // console.log('TODO: Init tags on word view');
    }

    initAnagrams() {
        // console.log('TODO: Init anagrams on word view');
    }

    initSimilar() {
        // console.log('TODO: Init similar on word view');
    }

    initWordFilter() {
        let wordFilter = document.getElementById('word-filter');
        if (wordFilter) {
            this.filter = <DoonWordFilter {...wordFilter.dataset} />;
            ReactDOM.render(this.filter, wordFilter);
        }
    }

    initWordActions() {
        let wordActions = document.querySelector('.word-actions');
        if (wordActions) {
            ReactDOM.render(<WordActions />, wordActions);
        }
    }

    async initPlayer() {
        let wordPlayer = document.querySelector('.play-it');
        if (wordPlayer) {
            let { id, word } = wordPlayer.dataset;
            ReactDOM.render(<WordPlayer id={id} word={word} />, wordPlayer);
        }
    }
}
