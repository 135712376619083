import user from './stores/user';

class DoonClient {
    constructor(base) {
        this.base = base || window.API_ENDPOINT || '';
        this.user = user;
    }

    async fetchJSON(url, params = null) {
        let options = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-User-Id': user.hash,
                'X-CSRF-TOKEN': user.csrf,
                'X-Requested-With': 'Doon Web',
            },
            cache: 'no-cache',
        };

        if (params) {
            options.method = 'POST';
            options.body = JSON.stringify(params);
        }

        try {
            let response = await fetch(url, options);
            return await response.json();
        } catch (e) {
            return null;
        }
    }

    async get(path, params = {}) {
        /**
         * TODO: Add X-User-Id, X-Requested-With, X-CSRF-TOKEN'
         */
        let requestStart = new Date().getTime();
        let url = `${this.base}${path}`;

        return await this.fetchJSON(url);
    }

    async post(path, params = {}, headers = {}) {
        let requestStart = new Date().getTime();
        let url = `${this.base}${path}`;
        return await this.fetchJSON(url, params);
    }
}
const client = new DoonClient();
export default client;
