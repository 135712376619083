import { nodelistToArray } from './utils';

export const cfg = {
    TRACKER: 'UA-186744-48',
};

class DoonAnalytics {
    constructor() {
        this.props = {};
        this.queue = [];
    }

    send() {
        let payload = nodelistToArray(arguments);
        if (
            'ga' in window &&
            window.ga.getAll !== undefined &&
            typeof window.ga.getAll === 'function'
        ) {
            this.emptyQueue();
            this.__send(payload);
        } else {
            this.queue.push(payload);
        }
    }

    __send(payload) {
        // TODO: Loop through props, add to payload and remove from props
        let trackers = window.ga.getAll();
        trackers.forEach((tracker) => {
            tracker.send(...payload);
        });
    }

    set(name, value) {
        this.send('set', name, value);
    }

    setCustomDimension(index, value) {
        this.set(`dimension${index}`, value);
    }

    setCustomMetric(index, value) {
        this.set(`metric${index}`, value);
    }

    addHitProp(name, value) {
        this.props[name] = value;
    }

    setUserId(userId) {
        // Google Analytics
        this.set('userId', userId);
    }

    trackPageview(page) {
        this.send('pageview', page);
    }

    trackUserTiming(category, variable, value) {
        this.send('timing', category, variable, value);
    }

    trackException(description, isFatal = false) {
        this.send('exception', {
            exDescription: description,
            exFatal: isFatal,
        });
    }

    trackEvent(
        category,
        action,
        label = null,
        value = null,
        nonInteraction = false,
        useBeacon = false
    ) {
        this.send('event', category, action, label, value, {
            useBeacon,
            nonInteraction,
        });
    }

    trackSiteSearch(query) {
        this.trackPageview(`/search/q=${query}`);
        this.trackEvent('DomainSearch', 'Query', query);
    }

    trackSearch(type, query) {
        this.trackPageview(`/${type}/q=${query}`);
        this.trackEvent('Search', type, query);
    }

    emptyQueue() {
        while (this.queue.length) {
            this.__send(this.queue.shift());
        }
    }
}

const analytics = new DoonAnalytics();

export const trackError = (error, fieldsObj = {}) => {
    ga(
        'send',
        'event',
        Object.assign(
            {
                eventCategory: 'Script',
                eventAction: 'error',
                eventLabel: (error && error.stack) || '(not set)',
                nonInteraction: true,
            },
            fieldsObj
        )
    );
};

const trackErrors = () => {
    const loadErrorEvents = (window.__e && window.__e.q) || [];
    const fieldsObj = { eventAction: 'uncaught error' };

    // Replay any stored load error events.
    for (let event of loadErrorEvents) {
        trackError(event.error, fieldsObj);
    }

    // Add a new listener to track event immediately.
    window.addEventListener('error', (event) => {
        trackError(event.error, fieldsObj);
    });
};

export const initGA = (userId = null) => {
    console.log('nah');
};

export default analytics;
