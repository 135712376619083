import React from 'react';
import client from '../client';

class DoonWordSayings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sayings: [],
        };
    }

    componentDidMount() {
        client.get(`/words/${this.props.id}/sayings`).then((response) => {
            let sayings = response.sayings.map((saying) => {
                return saying.saying;
            });
            this.setState({
                sayings,
            });
        });
    }

    render() {
        let header = this.state.sayings.length > 0 ? <h6>Ordspråk och uttryck</h6> : null;
        let i = 0;
        let content = this.state.sayings.map((saying) => {
            return <div key={'saying-' + i++}>{saying}</div>;
        });

        return (
            <div>
                {header}
                {content}
            </div>
        );
    }
}

export default DoonWordSayings;
