import React from 'react';
import { debounce } from '../utils';
import analytics from '../analytics';
import DoonDropDownButton from '../components/button';
import { SearchType } from '../filter';
import { wordStore, WordQuery } from '../stores/words';
import { observer } from 'mobx-react';
import './word.scss';

@observer
class DoonWordFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: this.props.query,
            apiQuery: '',
            queryType: SearchType.CONTAINS,
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.apiQuery.length > 0) {
            let oldQuery = new WordQuery(prevState.apiQuery, prevState.queryType);
            let newQuery = new WordQuery(this.state.apiQuery, this.state.queryType);

            if (!newQuery.isEqual(oldQuery)) {
                wordStore.find(newQuery);
            }
        }
    }

    componentDidMount() {
        this.handleSearchInput = debounce((searchField) => {
            analytics.trackSearch('wordFilter', searchField.value);
            this.setState({
                apiQuery: searchField.value,
            });
        }, 450);
    }

    onKeyDown(event) {
        if (event.key === 'Enter' && this.state.query && this.state.query.length > 0) {
            console.log('Submit to search page!', {
                type: this.state.queryType,
                query: this.state.query,
            });

            const query = encodeURIComponent(this.state.query.toLowerCase());

            let url = '';
            switch (this.state.queryType) {
                case 'contains':
                    url = `/ord-innehåller/${query}`;
                    break;
                case 'starts_with':
                    url = `/ordlista/${query}`;
                    break;
                case 'ends_with':
                    url = `/ord-slutar-på/${query}`;
                    break;
            }

            if (url.length > 0) {
                location.href = url;
            }
        }
    }

    handleSearch(event) {
        let element = event.target;
        this.setState({
            query: element.value,
        });
        this.handleSearchInput(element);
    }

    handleQueryFilter(value) {
        this.setState({
            queryType: value,
        });
    }

    getWords() {
        return wordStore.words.map((word) => {
            let url = `/ordlista/${word.word}/${word.id}`;
            return (
                <li key={word.id} className="list-group-item">
                    <a href={url}>{word.word}</a>
                    <small className="float-right">{word.type}</small>
                </li>
            );
        });
    }

    render() {
        let words =
            wordStore.words.length > 0 ? (
                <ul className="list-group list-group-scroll-overflow list-group-flush">
                    {this.getWords()}
                </ul>
            ) : null;
        let loader = wordStore.isLoading ? (
            <span>Laddar</span>
        ) : wordStore.loadingTime > 0 ? (
            `Hittade ${wordStore.words.length} ord på ${wordStore.loadingTime} sek`
        ) : null;
        return (
            <>
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Sök efter ord..."
                        onChange={this.handleSearch}
                        onKeyDown={this.onKeyDown}
                        value={this.state.query}
                    />
                    <div className="input-group-append">
                        <DoonDropDownButton
                            value={this.state.queryType}
                            items={[
                                { label: 'Innehåller', value: SearchType.CONTAINS },
                                { label: 'Startar med', value: SearchType.STARTS_WITH },
                                { label: 'Slutar med', value: SearchType.ENDS_WITH },
                            ]}
                            onChange={this.handleQueryFilter.bind(this)}
                        />
                    </div>
                </div>
                {words}
            </>
        );
    }
}

export default DoonWordFilter;
