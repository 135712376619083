import React from 'react';

class SynonymForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
        });
    }

    render() {
        let plcholder = `Ord som är synonym med ${this.props.word}`;

        let lblPlcHolder = this.state.value.length > 0 ? <small>{plcholder}</small> : null;

        return (
            <form>
                <h2>Lägg till synonymer</h2>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="synonym">Synonym {lblPlcHolder}</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={plcholder}
                                id="synonym"
                                onChange={this.handleChange}
                                value={this.state.value}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default SynonymForm;
