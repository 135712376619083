import { Word } from './doon/words';
import lozad from 'lozad';

import './scss/style.scss';
import analytics from './doon/analytics';
import { slugify } from './doon/word';

const supportsIntersectionObserver = () => 'IntersectionObserver' in window;

class DoonApp {
    constructor() {
        this.words = new Word();
        DoonApp.lazyLoader();
        this.observeSponsoredLinks();
    }

    static lazyLoader() {
        const observer = lozad();
        observer.observe();
    }

    observeSponsoredLinks() {
        if (!supportsIntersectionObserver()) return;

        const linkId = () => '_' + Math.random().toString(36).substr(2, 9);

        const linkRegister = {};

        const trackLinkVisibility = (entries, observer, link) => {
            entries.forEach((entry, i) => {
                if (entry.isIntersecting) {
                    let { target } = entry;
                    let { uid } = target.dataset;
                    if (linkRegister.hasOwnProperty(uid) && !linkRegister[uid]) {
                        linkRegister[uid] = true;
                        observer.disconnect();
                        analytics.trackEvent('Sponsored', 'View', uid);
                    }
                }
            });
        };

        const trackSponsoredAdClick = (event) => {
            const link = event.target.closest('a.link');
            if (link.dataset.uid) {
                analytics.trackEvent('Sponsored', 'Click', slugify(link.dataset.uid));
            }
        };

        const sponsoredLinks = document.querySelectorAll('a.link');
        sponsoredLinks.forEach((link) => {
            let uid = link.dataset.uid ? slugify(link.dataset.uid) : linkId();
            linkRegister[uid] = false;
            link.addEventListener('click', trackSponsoredAdClick, false);
            const linkObserver = new IntersectionObserver(
                (entries) => {
                    trackLinkVisibility(entries, linkObserver, link);
                },
                {
                    threshold: 1,
                }
            );
            linkObserver.observe(link);
        });
    }
}

const doon = new DoonApp();
