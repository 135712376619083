function nodelistToArray(nodeList) {
    return Array.prototype.slice.call(nodeList);
}

/**
 *
 * @param query
 * @returns [*]
 */
function querySelectorAllArray(query) {
    return nodelistToArray(document.querySelectorAll(query));
}

const objectCompare = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

function debounce(fn, delay) {
    let timer = null;
    return function () {
        const context = this,
            args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
}

function closest(selector, el) {
    let matchesFn;
    // find vendor prefix
    [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
    ].some(function (fn) {
        if (typeof document.body[fn] == 'function') {
            matchesFn = fn;
            return true;
        }
        return false;
    });

    if (!matchesFn) {
        return null;
    }

    let parent;

    // traverse parents
    while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
            return parent;
        }
        el = parent;
    }

    return null;
}

function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
}

function listToggle(array, value) {
    let clone = array.slice(0);
    let index = clone.indexOf(value);
    if (index > -1) {
        // Remove
        clone.splice(index, 1);
    } else {
        // Add
        clone.push(value);
    }
    return clone;
}

function domainify(text) {
    return text
        .replace(/\s|-/g, '')
        .replace(/å|ä/g, 'a')
        .replace(/ö/g, 'o')
        .replace(/\u0301|\u00e9/g, 'e');
}

export const elm = (element, props) => {
    let elm = document.createElement(element);
    for (let prop in props) {
        if (props.hasOwnProperty(prop)) {
            elm.setAttribute(prop, props[prop]);
        }
    }
    return elm;
};

export const onClickOutside = (selector, callback) => {
    document.addEventListener('click', (event) => {
        const { target } = event;
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
            const isClickInside = element.contains(target);
            if (!isClickInside) {
                callback();
            }
        });
    });
};

export {
    objectCompare,
    nodelistToArray,
    querySelectorAllArray,
    debounce,
    closest,
    capitalize,
    listToggle,
    domainify,
};
