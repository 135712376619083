import React from 'react';

class DoonDropDownButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || '',
            items: this.props.items || [],
        };
    }

    onChange(value, event) {
        event.preventDefault();
        if (this.state.value !== value) {
            this.setState({
                value,
            });
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        }
    }

    getItems() {
        return this.state.items.map((item) => {
            return (
                <a
                    className="dropdown-item"
                    onClick={this.onChange.bind(this, item.value)}
                    key={item.value}>
                    {item.label}
                </a>
            );
        });
    }

    getSelected() {
        let selected = null;
        this.state.items.forEach((item) => {
            if (item.value === this.state.value) {
                selected = item.label;
            }
        });

        return selected;
    }

    render() {
        return [
            <button
                key="dropdown-btn"
                type="button"
                className="btn btn-outline-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {this.getSelected()} <span className="caret" />
            </button>,
            <div key="dropdown-dd" className="dropdown-menu dropdown-menu-right">
                {this.getItems()}
            </div>,
        ];
    }
}

export default DoonDropDownButton;
