import React from 'react';
import client from './client';
import analytics from './analytics';
import { debounce } from './utils';

const classNames = require('classnames');

const slugify = (text) => {
    return text
        .toString()
        .toLowerCase()
        .replace('å', 'a')
        .replace('ä', 'a')
        .replace('ö', 'o')
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

class DoonWordSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            words: [],
            loading: false,
        };
    }

    static makeBold(input, highlight) {
        return input.replace(
            new RegExp('(\\b)(' + highlight.join('|') + ')(\\b)', 'ig'),
            '$1<strong>$2</strong>$3'
        );
    }

    render() {
        let btnClasses = classNames('btn', 'btn-success', {
            disabled: this.state.query.length < 3,
        });

        let loading = this.state.loading ? <div>Laddar ...</div> : null;

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h4 className="panel-title">Sök svenska ord</h4>
                </div>
                <div className="panel-body">
                    <div className="form-group">
                        <input
                            type="text"
                            autoComplete={'q'}
                            name="q"
                            className="form-control"
                            placeholder="Sök efter ord"
                            value={this.state.query}
                            onChange={this.queryChange.bind(this)}
                        />
                    </div>
                </div>

                {this.getWords()}
            </div>
        );
    }

    componentDidMount() {
        this.handleSearchInput = debounce((searchField) => {
            this.setState({
                loading: true,
            });
            let searchFor = searchField.value;
            analytics.trackSearch('word', searchFor);
            client.get(`/words/contains/${searchFor}`).then((response) => {
                let words = response.words;
                this.setState({
                    words,
                    loading: false,
                });
            });
        }, 800);
    }

    queryChange(event) {
        let query = event.target.value;
        this.setState({
            query,
        });
        this.handleSearchInput(event.target);
    }

    getWords() {
        if (this.state.words && this.state.words.length === 0 && !this.state.loading) {
            return '';
        }

        let words = this.state.words.map((word) => {
            return (
                <a
                    key={word.id}
                    onClick={DoonWordSearch.handleWordClick.bind(this, word)}
                    className="list-group-item">
                    {word.word}
                </a>
            );
        });

        let loading = this.state.loading ? (
            <div className="list-group-item">Laddar ...</div>
        ) : (
            words
        );

        return <div className="list-group">{loading}</div>;
    }

    static handleWordClick(word) {
        analytics.trackEvent('WordSearch', 'click', word.word);
        window.location.href = `/ordlista/${slugify(word.word)}/${word.id}`;
    }
}

export default DoonWordSearch;

export { slugify };
