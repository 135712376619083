import { observable } from 'mobx';
import { objectCompare } from '../utils';
import client from '../client';
import { SearchType } from '../filter';
import analytics from '../analytics';

const TypeLookup = {
    [SearchType.STARTS_WITH]: 'starts',
    [SearchType.ENDS_WITH]: 'ends',
    [SearchType.CONTAINS]: 'contains',
};

class WordQuery {
    query: string;
    type: string;

    constructor(query: string, type: string) {
        this.query = query;
        this.type = type;
    }

    asObject() {
        return {
            query: this.query,
            type: this.type,
        };
    }

    isEqual(wordQuery: WordQuery) {
        return objectCompare(this.asObject(), wordQuery.asObject());
    }
}

class WordType {
    name: string;
    slug: string;

    constructor(name: string, slug: string) {
        this.name = name;
        this.slug = slug;
    }
}

class Word {
    private _id: number;
    private _word: string;
    private _type: string;
    private _description: string;

    constructor(id: number, word: string, type: string, description: string) {
        this._id = id;
        this._word = word;
        this._type = type;
        this._description = description;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get word(): string {
        return this._word;
    }

    set word(value: string) {
        this._word = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }
}

class WordStore {
    get words(): Word[] {
        return this._words;
    }
    @observable private _types: WordType[] = [];
    @observable private _words: Word[] = [];
    @observable loading: boolean = false;
    @observable loadingTime: number = 0;
    private _cache: object;

    constructor(types: WordType[] = []) {
        this._types = types;
        this._cache = {};

        client.get('/words/types/list').then((response: any) => {
            let wordTypes = response.types;
            let types = [];
            for (let slug in wordTypes) {
                let wordType = new WordType(wordTypes[slug], slug);
                types.push(wordType);
            }
            this.types = types;
        });
    }

    set types(value: WordType[]) {
        this._types = value;
    }

    get types(): WordType[] {
        return this._types;
    }

    cachedResponse(url: string): any | null {
        return this._cache && this._cache.hasOwnProperty(url) ? this._cache[url] : null;
    }

    cacheResponse(url: string, response: any) {
        this._cache[url] = response;
    }

    async find(wordQuery: WordQuery) {
        let apiCall = `/words/${TypeLookup[wordQuery.type]}/${encodeURIComponent(wordQuery.query)}`;
        this.loading = true;
        let startCall = new Date().getTime();

        let response = this.cachedResponse(apiCall);
        if (!response) {
            response = await client.get(apiCall);
            this.cacheResponse(apiCall, response);
        }

        if (typeof response.words === 'undefined') {
            analytics.trackEvent(
                'FailedSearch',
                TypeLookup[wordQuery.type],
                wordQuery.query,
                null,
                true
            );
            this._words = [];
            return;
        }
        this._words = response.words.map((word: any) => {
            return new Word(word.id, word.word, word.type, word.description);
        });
        this.loading = false;
        let endCall = new Date().getTime();
        let loadingTime = endCall - startCall;
        this.loadingTime = loadingTime / 1000;
        analytics.trackUserTiming('WordSearch', 'ResponseTime', loadingTime);
    }
}

const wordStore = new WordStore();

export { wordStore, WordType, WordQuery };
