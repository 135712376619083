import { action, observable } from 'mobx';
import client from '../client';

class SoundStore {
    @observable sound: null | string;

    @action
    async loadSound(id: number) {
        console.log('Load sound');
        let sound = await client.get(`/words/${id}/sound`);
        if (
            sound &&
            sound.hasOwnProperty('payload') &&
            sound.payload.hasOwnProperty('path') &&
            sound.payload.path.length > 0
        ) {
            this.sound = sound.payload.path;
            return sound.payload.path;
        } else {
            return null;
        }
    }
}

const soundStore = new SoundStore();
export { soundStore };
